<template>
  <div class="address">
<!--    <detail-view :dataArray="itemData"/>-->
    <detail-view />
  </div>
</template>

<script>
  // import AccountAPI from '@/api/account'
  import DetailView from "@/components/global/DetailView";
  // import { assignServices, assignMeters, getSumPay, getAddressTitle } from "@/utils";

  export default {
    name: 'NotAuthenticatedUserView',
    props: {
      schema: {
        type: String,
        required: false
      },
      secret_id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        item: {},
      }
    },
    methods: {
      // async getAccount() {
      //   try {
      //     const {data} = await AccountAPI.accountView(this.schema, this.secret_id);
      //     data.services = assignServices(data.services);
      //     data.meters = assignMeters(data.meters, data.services);
      //     data.sum_pay = getSumPay(data);
      //     data.title = getAddressTitle(data.person);
      //     data.full_name = data.person.last_name;
      //
      //
      //     this.item = data;
      //   }
      //   catch (error) {
      //       this.$snackbar("Помилка отримання адреси", 'error')
      //   }
      // },
    },
    computed: {
      // itemData() {
      //   return this.item;
      // },
    },
    mounted() {
      // this.getAccount();
    },
    components: {
      DetailView,
    }
  }
</script>

<style scoped lang="scss">
</style>
